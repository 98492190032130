import { useState, useEffect, memo } from "react";
import weatherService from "../../services/weather.service";
import { ResponsiveLine } from "@nivo/line";
import getRetryString from "../../helpers/getRetryString";

const MyResponsiveLine = ({ data }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: true,
      reverse: false,
    }}
    yFormat=" >-.2f"
    curve="natural"
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    lineWidth={4}
    enableGridX={false}
    enableGridY={false}
    colors={{ scheme: "category10" }}
    enablePoints={false}
    pointSize={8}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    areaOpacity={0}
    isInteractive={false}
    useMesh={true}
    legends={[]}
  />
);

function HourlyWeather({ address }) {
  const [forecastURL, setForecastURL] = useState("");
  const [hourlyForecastURL, setHourlyForecastURL] = useState("");
  const [periods, setPeriods] = useState([]);
  const [locationInfo, setLocationInfo] = useState({});
  const [formattedData, setFormattedData] = useState([]);
  const [rateLimitHit, setRateLimitHit] = useState(false);
  const [rateLimitRetry, setRateLimitRetry] = useState(0);

  const getCoordinates = () => {
    return new Promise((resolve, reject) => {
      if (rateLimitHit) return reject("rate limit already hit");

      if (address) {
        // console.log(address);
        weatherService
          .getAddress(address)
          .then((res) => {
            const address = res.data.addressMatches[0];
            // console.log(address);
            resolve({
              latitude: address.coordinates.y,
              longitude: address.coordinates.x,
            });
          })
          .catch((err) => {
            const retry = err.response.headers["retry-after"];
            if (retry) {
              const retryTime = parseInt(retry);
              setRateLimitHit(true);
              setRateLimitRetry(retryTime);
            } else {
              console.log("error getting address coordinates", err);
            }
            return reject(err);
          });
      } else {
        console.log("no address found");
        return reject("no address found");
      }
    });
  };

  const setURLS = async (position) => {
    try {
      let location = await getCoordinates();
      console.log(location);
      weatherService
        .getLocationInfo(location.latitude, location.longitude)
        .then((res) => {
          // console.log(res);
          localStorage.setItem(
            "locationInfo",
            JSON.stringify(res.data.properties)
          );
          setLocationInfo(res.data.properties);
          setForecastURL(res.data.properties.forecast);
          setHourlyForecastURL(res.data.properties.forecastHourly);
          weatherService
            .getForecast(res.data.properties.forecastHourly)
            .then((res) => {
              console.log(res.data.properties.periods);
              setPeriods(res.data.properties.periods.splice(0, 8));
            });
        });
    } catch (error) {
      console.log("error setting urls", error);
    }
  };

  useEffect(() => {
    setURLS();
  }, [address]);

  useEffect(() => {
    weatherService.getForecast(hourlyForecastURL).then((res) => {
      console.log(res.data.properties.periods);
      // get first 8 hours
      setPeriods(res.data.properties.periods.slice(0, 8));
    });
  }, [hourlyForecastURL]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("INTERVALLLLLL");
      weatherService
        .getForecast(hourlyForecastURL)
        .then((res) => {
          setPeriods(res.data.properties.periods.slice(0, 8));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);
    return () => clearInterval(interval);
  }, [hourlyForecastURL]);

  useEffect(() => {
    if (periods.length > 0) {
      let newData = [
        {
          id: "weather",
          color: "rgb(255, 255, 255)",
          data: [],
        },
      ];

      periods.forEach((period, i) => {
        const time = period.startTime.split("T")[1].split(":")[0];
        newData[0].data.push({ x: time, y: period.temperature });
      });

      console.log(newData);
      setFormattedData(newData);
    }
  }, [periods]);

  return (
    <>
      {rateLimitHit ? (
        <div className="hourly-weather d-flex justify-content-center align-items-center">
          <p className="my-1">
            {"Too many requests, please try again in: " +
              getRetryString(rateLimitRetry)}
          </p>
        </div>
      ) : periods.length > 0 && formattedData.length > 0 ? (
        <div className="hourly-weather">
          <div className="weather-line-chart">
            <MyResponsiveLine data={formattedData} />
          </div>
          {periods.map((period, i) => {
            let time = period.startTime.split("T")[1].split(":")[0];
            if (time >= 12) {
              if (time !== "12") {
                time -= 12;
                time = parseInt(time) + "pm";
              } else {
                time = "12pm";
              }
            } else {
              time = parseInt(time) + "am";
            }

            // console.log("Time: ", time, i);
            return (
              <div className="hourly-weather__single">
                <div className="period-time">{time}</div>
                {/* <img
                  src={period.icon}
                  className="weather-icon"
                /> */}
                <div className="period-temp">{`${period.temperature}°`}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

export default HourlyWeather;
