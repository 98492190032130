import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class TokenService {
  async addInstaAuthToken(token, username, platform) {
    let res = await axios.post(API + "token/add-insta-auth-token", {
      token,
      username,
      platform,
    });

    return res.data;
  }

  async addTokenToCard(tokenId, cardId) {
    console.log("tokenId: ", tokenId, "cardId: ", cardId);
    let res = await axios.post(
      API + "token/add-account-to-card",
      {
        tokenId,
        cardId,
      },
      {
        headers: authHeader(),
      }
    );

    return res.data;
  }

  async tokenExists(username, platform) {
    let res = await axios.post(
      API + "token/token-exists/",
      { username, platform },
      {
        headers: authHeader(),
      }
    );

    return res.data.id;
  }

  async getInstaUsername(token) {
    try {
      const res = await axios.get(
        `https://graph.instagram.com/me?fields=id,username&access_token=${token}`
      );

      return res.data.username;
    } catch (err) {
      console.log("error getting insta username: ", err);
      return "";
    }
  }
}

export default new TokenService();
