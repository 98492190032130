import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class ClientAddressService {
  async postClientAddress(boardName) {
    const ipAddress = (await axios.get("https://api.ipify.org/?format=json"))
      .data.ip;

    return axios.post(
      API + "client-address",
      { ipAddress, boardName },
      { headers: authHeader() }
    );
  }

  getClientAddressByIp(ipAddress) {
    return axios.get(API + `client-address/${ipAddress}`, {
      headers: authHeader(),
    });
  }

  getClientAddressesByBoardName(boardName) {
    return axios.get(API + `client-addresses/${boardName}`, {
      headers: authHeader(),
    });
  }
}

export default new ClientAddressService();
