/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useRef, useCallback, memo, useMemo } from "react";
import "../salonDirectory/directory.css";
import { useParams } from "react-router-dom";
import Cards from "../../components/directory/Cards";
import fp from "./fp-01.png";
import Contact from "../../components/directory/Contact";
import Video from "../../components/directory/Video";
import Accessibility from "../../components/directory/Accessibility";
import Featured from "../../components/directory/Featured";
import Footer from "../../components/directory/Footer";
import boardService from "../../services/board.service";
import ScreenSaver from "../../components/directory/ScreenSaver";
import Single from "../../components/directory/Single";
import cloudinaryService from "../../services/cloudinary.service";
import AbsoluteVideo from "../../components/AbsoluteVideo";
import useAnalytics from "./useAnalytics";
import "animate.css";
import useTimeout from "../../services/useTimeout";
import clientAddressService from "../../services/clientAddress.service";
import RateLimitHit from "../../components/rateLimitHit/RateLimitHit";

function Directory() {
  const params = useParams();
  const [board, setBoard] = useState({});
  const [accentColor, setAccentColor] = useState("");
  const [background, setBackground] = useState("./black-bg.png");
  const [cards, setCards] = useState([]);
  const [filter, setFilter] = useState("all");
  const [absoluteVideoClass, setAbsoluteVideoClass] =
    useState("video-main-view");
  const [showAda, setShowAda] = useState(false);
  const [zIndex, setZIndex] = useState(0);
  const [boardState, setBoardState] = useState("main");
  const [singleBoard, setSingleBoard] = useState({});
  const [instaImages, setInstaImages] = useState([]);
  const [allSitemaps, setAllSitemaps] = useState({});
  const [videoCoords, setVideoCoords] = useState({});
  const [videoDim, setVideoDim] = useState({});
  const [defaultSitemap, setDefaultSitemap] = useState("");
  const [screensaverActive, setScreensaverActive] = useState(true);
  const [screensaverVisible, setScreensaverVisible] = useState(true);
  const [SCREENSAVER_DELAY_MS, setScreensaverDelay] = useState(60000);
  const [SCREENSAVER_ACTIVE_TIME_MS, setScreensaverActiveTime] =
    useState(180000);
  const [SCREENSAVER_INACTIVE_TIME_MS, setScreensaverInactiveTime] =
    useState(60000);
  const [rateLimitHit, setRateLimitHit] = useState(false);
  const [rateLimitRetry, setRateLimitRetry] = useState(0);
  const screensaverTimeout = useRef();
  const secondTimer = useRef();
  const status = useAnalytics(board.board_name);
  const timeoutHook = useTimeout;

  // const SCREENSAVER_DELAY_MS = 60000;
  // const SCREENSAVER_ACTIVE_TIME_MS = 180000;
  // const SCREENSAVER_INACTIVE_TIME_MS = 60000;

  const positionVideo = () => {
    if (screensaverActive && screensaverVisible) {
      setZIndex(3);
      setVideoCoords(getVideoCoords("screensaverVideoContainer"));
      setVideoDim(getVideoCoords("screensaverVideoContainer"));
      setTimeout(() => {
        setVideoCoords(getVideoCoords("screensaverVideoContainer"));
        setVideoDim(getVideoDimensions("screensaverVideoContainer"));
      }, 700);
    } else {
      setZIndex(0);
      setVideoCoords(getVideoCoords("videoContainer"));
      setVideoDim(getVideoDimensions("videoContainer"));
      setTimeout(() => {
        setVideoCoords(getVideoCoords("videoContainer"));
        setVideoDim(getVideoDimensions("videoContainer"));
      }, 700);
    }
  };

  const memoizedSitemaps = useCallback(() => {
    cloudinaryService
      .getSitemaps({
        assetsFolder: board.assets_folder,
      })
      .then((res) => {
        // console.log("default: ", res);
        let foundSitemap = res.data.resources.find((elem) => {
          let splitPath = elem.public_id.split("/");
          let name = splitPath.pop().split("_")[1];
          if (name === "default") return true;
          else return false;
        });
        let newSitemaps = {};
        res.data.resources.forEach((elem) => {
          let splitPath = elem.public_id.split("/");
          let name = splitPath.pop().split("_")[1];
          newSitemaps[name] = elem.secure_url;
        });
        setAllSitemaps(newSitemaps);
        positionVideo();
        setDefaultSitemap(foundSitemap.secure_url);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }, [board]);

  const resetView = () => {
    setBoardState("main");
    setFilter("all");
    setShowAda(false);
  };

  const activeScreensaver = () => {
    // clearTimeout(screensaverTimeout.current);
    // clearTimeout(secondTimer.current);
    setScreensaverActive(true);
    setScreensaverVisible(true);
    resetView();
    loop();
    function loop() {
      const timerRef = setTimeout(() => {
        setScreensaverVisible(false);
        const timerRef2 = setTimeout(() => {
          setScreensaverVisible(true);
          loop();
        }, SCREENSAVER_INACTIVE_TIME_MS);
        clearTimeout(timerRef);
        secondTimer.current = timerRef2;
      }, SCREENSAVER_ACTIVE_TIME_MS);
      clearTimeout(secondTimer.current);
      secondTimer.current = timerRef;
    }
    return () => clearTimeout(secondTimer.current);
  };

  const getVideoCoords = (id) => {
    let bodyRect = document.body.getBoundingClientRect();
    if (!bodyRect) {
      return {
        top: "0px",
        left: "0px",
      };
    }
    let container = document.getElementById(id);
    if (!container) {
      return {
        top: "0px",
        right: "0px",
      };
    }
    let elemRect = container.getBoundingClientRect();
    let offsetTop = elemRect.top - bodyRect.top;
    let offsetLeft = elemRect.left - bodyRect.left;
    return {
      top: `${offsetTop}px`,
      left: `${offsetLeft}px`,
    };
  };

  const getVideoDimensions = (id) => {
    let container = document.getElementById(id);
    if (!container) {
      return {
        width: 0,
        height: 0,
      };
    }
    let width = container.clientWidth;
    let height = container.clientHeight;

    return {
      width: width,
      height: height,
    };
  };

  useEffect(() => {
    positionVideo();
  }, [boardState]);

  useEffect(() => {
    positionVideo();
  }, [screensaverActive, screensaverVisible]);

  // useEffect(() => {
  //   console.log(videoCoords);
  // }, [videoCoords]);

  const grabBoardData = useCallback(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    boardService
      .getBoardByName({ board_name: params.dir })
      .then((res) => {
        // console.log(res.data);
        // requires minimum time to be 15 seconds for each time

        if (res.data.screensaver_active_time >= 15000) {
          setScreensaverActiveTime(res.data.screensaver_active_time);
        }
        if (res.data.screensaver_delay >= 15000) {
          setScreensaverDelay(res.data.screensaver_delay);
        }
        if (res.data.screensaver_inactive_time >= 15000) {
          setScreensaverInactiveTime(res.data.screensaver_inactive_time);
        }
        setBoard(res.data);
        setBackground(
          res.data.background
            ? res.data.background
            : "https://res.cloudinary.com/dmmk1bq5z/image/upload/v1669761265/Backgrounds/dark-plain-gray-wall-product-background_xo9os4.jpg"
        );
        setAccentColor(res.data.accent_color);
        let cards = res.data.suites
          .filter((suite) => suite.cards.length > 0)
          .map((suite) => {
            // console.log(suite);
            let newCards = suite.cards.map((card) => {
              return {
                ...card,
                suiteNumber: suite.suite_number,
                customName: suite.custom_name ? suite.custom_name : null,
              };
            });
            // console.log(newCards);
            return newCards;
          });
        // console.log(cards);
        cards = cards.flat();
        // console.log(cards);
        setCards(cards);
      })
      .catch((err) => {
        const retry = err.response.headers["retry-after"];
        if (retry) {
          const retryTime = parseInt(retry);
          setRateLimitHit(true);
          setRateLimitRetry(retryTime);
        } else {
          console.log("error getting board by name", err);
        }
      });
  }, [params, rateLimitHit]);

  useEffect(grabBoardData, [params, grabBoardData]);

  useEffect(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    // console.log("Board from useEffect: ", board);
    // Pings device on render
    const { board_name } = board;
    if (board_name) {
      clientAddressService.postClientAddress(board_name).catch((err) => {
        const retry = err.response.headers["retry-after"];
        if (retry) {
          const retryTime = parseInt(retry);
          setRateLimitHit(true);
          setRateLimitRetry(retryTime);
        } else {
          console.log("error getting board by name", err);
        }
      });
    }

    // grab images from instagram
    memoizedSitemaps();
    boardService
      .instaImages({ boardId: board.id })
      .then((res) => {
        setInstaImages(res.data);
        // console.log("instaImages: ", res.data);
      })
      .catch((err) => {
        const retry = err.response.headers["retry-after"];
        if (retry) {
          const retryTime = parseInt(retry);
          setRateLimitHit(true);
          setRateLimitRetry(retryTime);
        } else {
          console.log("Error getting insta images", err);
        }
      });
  }, [board, rateLimitHit]);

  useEffect(() => {
    activeScreensaver();
  }, [
    SCREENSAVER_ACTIVE_TIME_MS,
    SCREENSAVER_DELAY_MS,
    SCREENSAVER_INACTIVE_TIME_MS,
  ]);

  useEffect(() => {
    const refreshTimerRef = setTimeout(() => {
      window.location.reload();
    }, 3600000);

    return () => clearTimeout(refreshTimerRef);
  }, []);

  const screensaverClicked = useCallback(() => {
    setScreensaverActive(false);
    startTimeout();
  }, []);

  const startTimeout = () => {
    clearTimeout(screensaverTimeout.current);
    clearTimeout(secondTimer.current);
    const timeout = setTimeout(() => activeScreensaver(), SCREENSAVER_DELAY_MS);
    screensaverTimeout.current = timeout;
    return () => clearTimeout(screensaverTimeout.current);
  };

  const appTouched = useCallback((ev) => {
    if (ev.target.id !== "screensaver") {
      startTimeout();
    }
  });

  const handleSingle = (card) => {
    setSingleBoard(card);
    setBoardState("single");
    setAbsoluteVideoClass("video-single-view");
  };

  const handleBack = () => {
    setSingleBoard({});
    setBoardState("main");
    setAbsoluteVideoClass("video-main-view");
  };

  const handleFilter = (name) => {
    setFilter(name);
  };

  const handleAda = () => {
    setShowAda((prev) => !prev);
  };

  const currentFilter = useMemo(() => {
    let words = filter.split(" ");
    // console.log(words);
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    words = words.join(" ");

    return words;
  }, [filter]);

  return (
    <div
      className="dir-page-container"
      style={{
        background: `url(${background})`,
      }}
      onClick={appTouched}
    >
      {rateLimitHit ? (
        <RateLimitHit numSeconds={rateLimitRetry} />
      ) : (
        <>
          {board.playlist_key && videoCoords.top && (
            // <div style={videoCoords}>
            <AbsoluteVideo
              embedId={board.playlist_key}
              coords={videoCoords}
              height={videoDim.height}
              width={videoDim.width}
              zIndex={zIndex}
            />
            // </div>
          )}
          {screensaverActive && screensaverVisible && (
            <ScreenSaver
              clicked={screensaverClicked}
              accentColor={accentColor}
              instaImages={instaImages}
              board={board}
              website={board.qr_code}
              cards={cards}
            />
          )}
          <div className={boardState === "single" ? "directory-hidden" : ""}>
            <Cards
              handleSingle={handleSingle}
              filter={filter}
              cards={cards}
            />
            <div className="info-container main-info">
              <Contact
                phone={board.contact_phone}
                email={board.contact_email}
                name={board.contact_name}
                header={
                  board.custom_contact_header
                    ? board.custom_contact_header
                    : "For information about renting suites, contact:"
                }
              />
              <div
                id={boardState === "single" ? "" : "videoContainer"}
                className="video-container"
              >
                <div className="iframe-container"></div>
              </div>
              <div className="map-container">
                <img src={defaultSitemap ? defaultSitemap : fp} />
              </div>
              <div className="current-filter">
                <h2>Current Filter: </h2>
                <h3>{currentFilter}</h3>
              </div>
            </div>
            <div>
              {showAda ? (
                <div
                  id="accessibility"
                  // style={{ display: adaDisplay }}
                  className="accessibility-container"
                >
                  <Accessibility
                    cards={cards}
                    handleSingle={handleSingle}
                  />
                </div>
              ) : null}
              <Footer
                cards={cards}
                handleAda={handleAda}
                handleFilter={handleFilter}
              />
            </div>
          </div>
          {boardState === "single" && (
            <Single
              handleBack={handleBack}
              playlist={`https://www.youtube.com/embed/${board.playlist_key}`}
              card={singleBoard}
              allSitemaps={allSitemaps}
            />
          )}
        </>
      )}
    </div>
  );
}
export default memo(Directory);
