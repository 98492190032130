import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../pages/digitalSignage/digitalSignage.css";
import Marquee from "react-fast-marquee";
import boardService from "../../services/board.service";
import {
  BsEnvelopeFill,
  BsFillTelephoneFill,
  BsPersonFill,
} from "react-icons/bs";
import getRetryString from "../../helpers/getRetryString";

function Ticker() {
  const params = useParams();
  const [board, setBoard] = useState({});
  const [accentColor, setAccentColor] = useState("");
  const [background, setBackground] = useState("black-bg.png");
  const [cards, setCards] = useState([]);
  const [rateLimitHit, setRateLimitHit] = useState(false);
  const [rateLimitRetry, setRateLimitRetry] = useState(0);

  useEffect(
    function grabBoardData() {
      if (params) {
        // if the rate limit has already been hit, do nothing
        if (rateLimitHit) return;

        boardService
          .getBoardByName({ board_name: params.dir })
          .then((res) => {
            console.log(res.data);
            setBoard(res.data);
            setBackground(res.data.background);
            setAccentColor(res.data.accent_color);
            let cards = res.data.suites
              .filter((suite) => suite.cards.length > 0)
              .map((suite) => {
                let newCards = suite.cards.map((card) => {
                  return {
                    ...card,
                    suiteNumber: suite.suite_number,
                    customName: suite.custom_name ? suite.custom_name : null,
                  };
                });
                return newCards;
              });
            cards = cards.flat();
            setCards(cards);
          })
          .catch((err) => {
            const retry = err.response.headers["retry-after"];
            if (retry) {
              const retryTime = parseInt(retry);
              setRateLimitHit(true);
              setRateLimitRetry(retryTime);
            } else {
              console.log("error getting board by name", err);
            }
          });
      }
      setTimeout(() => {});
    },
    [params, rateLimitHit]
  );

  return (
    <div>
      <div>
        <Marquee
          className="marquee-container"
          gradient={false}
          pauseOnHover={false}
          speed={20}
        >
          {rateLimitHit ? (
            <p className="mt-1 mb-0">
              {"Too many requests, please try again in: " +
                getRetryString(rateLimitRetry)}
            </p>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th>
                    <BsPersonFill /> &nbsp;&nbsp;
                  </th>
                  <td className="ticker-icon">{board.contact_name}&nbsp; </td>
                  <th>
                    <BsFillTelephoneFill /> &nbsp;&nbsp;
                  </th>
                  <td className="ticker-icon">{board.contact_phone}&nbsp;</td>
                  <th>
                    <BsEnvelopeFill /> &nbsp;&nbsp;
                  </th>
                  <td className="ticker-icon">{board.contact_email}&nbsp;</td>
                </tr>
              </tbody>
            </table>
          )}
        </Marquee>
      </div>
    </div>
  );
}

export default Ticker;
