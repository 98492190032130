import { useState, useEffect } from "react";
import "../../pages/digitalSignage/digitalSignage.css";

function Announcements({ announcement }) {
  const [announceArray, setAnnounceArray] = useState([]);

  useEffect(() => {
    if (announcement) {
      console.log(announcement);
      const array = announcement.split(",");
      setAnnounceArray(array);
    }
  }, [announcement]);

  return (
    <div className="announcement-container">
      <label className="announcement-header">Announcements: </label>
      <div className="announcements-list">
        {announceArray.map((announcement, index) => (
          <ul className="">
            <li className="announcement-message">{announcement}</li>
          </ul>
        ))}
      </div>
    </div>
  );
}

export default Announcements;
