import { useState } from "react";

function useAsyncTransition() {
  const [isPending, setIsPending] = useState(false);

  const startTransition = async (callback) => {
    setIsPending(true);
    await callback();
    setIsPending(false);
  };

  return [isPending, startTransition];
}

export default useAsyncTransition;
