import { useState, memo, useEffect, useMemo } from "react";
import "../../pages/generalDirectory/generalDirectory.css";
import "animate.css";
import Carousel from "./Carousel";
import { useSpring, animated } from "@react-spring/web";
import { useGeneralDirectoryContext } from "./context/GeneralDirectoryContext";
import GeneralTapIcon from "./GeneralTapIcon";

function GeneralScreenSaver({ clicked }) {
  const [fade, setFade] = useState(false);
  const [date, setDate] = useState(new Date());
  const { boardData } = useGeneralDirectoryContext();

  const background = useMemo(() => {
    return `url(${boardData.backgroundImage})`;
  }, [boardData.backgroundImage]);

  const handleOnClick = () => {
    setFade(true);
    setTimeout(() => {
      setFade(false);
      clicked();
    }, 2000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const fadeOutProps = useSpring({
    opacity: fade ? 0 : 1,
    config: { duration: 200 },
  });

  return (
    <animated.div
      className={`general-screensaver ${fade ? "fade-out" : ""}`}
      style={fadeOutProps}
      onClick={handleOnClick}
    >
      <Carousel />
      <div className="general-directory-time">
        {date.toLocaleDateString("en-US", {
          month: "short",
          weekday: "long",
          day: "numeric",
        })}
        <br />
        {date.toLocaleTimeString("en-US", {
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        })}
      </div>
      <GeneralTapIcon clicked={clicked} />
    </animated.div>
  );
}

export default memo(GeneralScreenSaver);
