import { useState, memo } from "react";
import "../../pages/generalDirectory/GeneralDirectory";
import "animate.css";
import icon from "./touch-icon5.png";
import { useSpring, animated } from "@react-spring/web";

function GeneralTapIcon() {
  const [fade, setFade] = useState(false);

  const props = useSpring({
    to: { opacity: 1, transform: "scale(1)" },
    from: { opacity: 0.3, transform: "scale(0.85)" },
    reset: true,
    reverse: fade,
    delay: 0,
    config: { duration: 1000 },
    onRest: () => setFade(!fade),
  });

  return (
    <div className="general-screensaver-touch">
      <div className="icon-container">
        <animated.div style={props}>
          <img
            className="general-touch-icon"
            alt="touch icon"
            src={icon}
            width="70"
            height="90"
          />
        </animated.div>
        <div className="touch-text">Tap screen to begin!</div>
      </div>
    </div>
  );
}
export default memo(GeneralTapIcon);
