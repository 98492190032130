import { Button } from "react-bootstrap";

function Navigation({
  sitemaps,
  accentColor,
  selectedSitemap,
  setSelectedSitemap,
}) {
  return (
    <div
      className="general-navigation"
      style={{ border: `solid 5px ${accentColor}`, borderLeft: "none" }}
    >
      {Object.keys(sitemaps).map((sitemapName) => {
        if (sitemapName === "default") return null;

        return (
          <div
            className={
              sitemapName === selectedSitemap
                ? "navigation-box outlined"
                : "navigation-box"
            }
            key={sitemapName + "-div"}
            style={{ outlineColor: accentColor || "cornflowerblue" }}
          >
            <Button
              className="navigation-button"
              variant="outline-dark"
              size="lg"
              key={sitemapName + "-button"}
              onClick={() => {
                setSelectedSitemap(sitemapName);
              }}
            >
              {sitemapName}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

export default Navigation;
