import { useCallback, useState, useEffect } from "react";
import useInterval from "../../services/useInterval";

const DELAY = 600000;

function useAnalytics(boardName) {
  const [clicks, setClicks] = useState(0);
  const [status, setStatus] = useState("");
  let url = window.location.href.includes("localhost")
    ? "http://localhost:8080/api"
    : "https://adiuvo-digital-display.herokuapp.com/api";
  // let clicks = 0;

  async function postClicks(something) {
    const api = `${url}/analytics/post-clicks`;
    console.log("postClicks: " + something);

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let day = today.getDay();
    let hour = today.getHours() + 1;
    let minutes = today.getMinutes();

    let data = {
      clicks: clicks,
      boardName: boardName,
      year: year,
      month: month,
      date: date,
      day: day,
      hour: hour,
      minutes: minutes,
    };
    const config = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    console.log(
      `Inside postClicks. Url is ${url} data: ${JSON.stringify(data)}`
    );

    const response = await fetch(api, config);
    console.log("res: " + JSON.stringify(response));

    return response.json();
  }

  function handleClick(e) {
    console.log("this is sparta");
    setClicks((prevClicks) => prevClicks + 1);
    console.log(clicks);
  }

  useEffect(() => {
    console.log("clicksEFFECT");
    document.body.addEventListener("click", handleClick);

    return () => document.body.removeEventListener("click", handleClick);
  }, [clicks]);

  useInterval(() => {
    postClicks(clicks).then((res) => {
      console.log(res);
      setClicks(0);
    });
  }, DELAY);

  return status;
}

export default useAnalytics;
