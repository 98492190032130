import { Link } from "react-router-dom";
import "./page-not-found.css";

function PageNotFound({ page = "" }) {
  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center not-found-container">
      <div className="w-25 h-auto d-flex flex-column justify-content-center align-items-center bg-light border border-2 border-primary rounded shadow-sm p-2">
        <h2 className="fs-1">Oops!</h2>
        <p className="fs-1">404</p>
        <p className="fs-2">Page Not Found</p>
        <br />
        <p>Attempting to access route:</p>
        <p>{page ? page : window.location.pathname}</p>
        <br />
        <p>You might be seeing this because you're not logged in</p>
        <a href="/login">
          <button className="btn btn-standard">Login</button>
        </a>
      </div>
    </div>
  );
}

export default PageNotFound;
