import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/auth/"
  : "https://adiuvo-digital-display.herokuapp.com/api/auth/";

const pathsToIgnore = [
  "login",
  "directory",
  "digital-signage",
  "suite-instagram",
  "general-directory",
  "instagram",
  "test",
];

class AuthService {
  login(username, password) {
    return axios
      .post(API + "signin", {
        username,
        password,
      })
      .then((res) => {
        if (res.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
      });
  }

  createUser({ username, password, email, phone, name }) {
    return axios.post(API + "signup", {
      username,
      password,
      email,
      phone,
      name,
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    const pathname = window.location.pathname;
    if (pathsToIgnore.some((path) => pathname.includes(path))) {
      return;
    }

    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch {
      localStorage.clear();
    }
  }

  deleteUser(id) {
    return axios.post(API + "delete-user", id, { headers: authHeader() });
  }

  updatePassword(id) {
    return axios.post(API + "update-password", id, { headers: authHeader() });
  }

  updateUsername(userData) {
    return axios.post(API + "update-username", userData, {
      headers: authHeader(),
    });
  }

  instaCode(data) {
    return axios.post(API + "insta-code", data, { headers: authHeader() });
  }
}
export default new AuthService();
