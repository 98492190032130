import axios from "axios";
import authHeader from "./auth.header";

const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class PanicModeService {
  getPanicMode() {
    return axios.get(API + "panic-mode", { headers: authHeader() });
  }

  setPanicMode(isPanicMode) {
    return axios.post(
      API + "panic-mode",
      { isPanicMode },
      { headers: authHeader() }
    );
  }
}

export default new PanicModeService();
