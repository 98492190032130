function getContrastTextColor(hexcode) {
  const red = parseInt(hexcode.substr(1, 2), 16);
  const green = parseInt(hexcode.substr(3, 2), 16);
  const blue = parseInt(hexcode.substr(5, 2), 16);

  const rgbArray = [red / 255, green / 255, blue / 255];
  const rgbLuminosity = rgbArray.map((color) => {
    if (color <= 0.03928) return color / 12.92;
    return Math.pow((color + 0.055) / 1.055, 2.4);
  });

  const overallLuminosity =
    0.2126 * rgbLuminosity[0] +
    0.7152 * rgbLuminosity[1] +
    0.0722 * rgbLuminosity[2];

  const light = "#fff";
  const dark = "#000";

  return overallLuminosity > 0.179 ? dark : light;
}

export default getContrastTextColor;
