import React from "react";

const YoutubeEmbed = ({ embedId }) => (
  <div className="signage-video-container iframe-holder">
    <iframe
      title="signage-board-video-player"
      className="signage-video"
      src={`https://www.youtube.com/embed/videoseries?iv_load_policy=3&mute=1&loop=1&controls=0&fs=0&modestbranding=1&autohide=1&rel=0&disablekb=1&frameborder=0&showinfo=0&autoplay=1&amp;list=${embedId}`}
    />
  </div>
);

export default YoutubeEmbed;
