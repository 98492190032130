import React, { useEffect, useState, useCallback, useRef } from "react";
import "./generalDirectory.css";
import { useParams } from "react-router-dom";
import VideoAd from "../../components/generalDirectory/GeneralVideoAd";
import PoweredBy from "../../components/generalDirectory/PoweredBy";
import QRCode from "../../components/directory/QRCode";
import cloudinaryService from "../../services/cloudinary.service";
import GeneralScreenSaver from "../../components/generalDirectory/GeneralScreenSaver";
import GeneralDirectoryContext from "../../components/generalDirectory/context/GeneralDirectoryContext";
import useGeneralDirectoryData from "../../components/generalDirectory/hooks/useGeneralDirectoryData";
import Navigation from "../../components/generalDirectory/Navigation";
import ErrorBoundary from "../../components/generalDirectory/ErrorBoundary";
import clientAddressService from "../../services/clientAddress.service";
import RateLimitHit from "../../components/rateLimitHit/RateLimitHit";

// setInterval(() => {
//   window.location.reload();
// }, 30000);

function GeneralDirectory() {
  const [
    boardData,
    slides,
    defaultLogo,
    suites,
    rateLimitHit,
    setRateLimitHit,
    rateLimitRetry,
    setRateLimitRetry,
  ] = useGeneralDirectoryData();
  const params = useParams();
  const [dateState, setDateState] = useState(new Date());
  const [allSitemaps, setAllSitemaps] = useState([]);
  const [selectedSitemap, setSelectedSitemap] = useState("");
  const [screensaverActive, setScreensaverActive] = useState(true);
  const [screensaverVisible, setScreensaverVisible] = useState(true);
  const [SCREENSAVER_DELAY_MS, setScreensaverDelay] = useState(60000);
  const [SCREENSAVER_ACTIVE_TIME_MS, setScreensaverActiveTime] =
    useState(180000);
  const [SCREENSAVER_INACTIVE_TIME_MS, setScreensaverInactiveTime] =
    useState(60000);
  const [slideIndex, setSlideIndex] = useState(0);
  const screensaverTimeout = useRef();
  const secondTimer = useRef();

  const activeScreensaver = () => {
    setScreensaverActive(true);
    setScreensaverVisible(true);
    setSelectedSitemap("");
    function loop() {
      const timerRef = setTimeout(() => {
        setScreensaverVisible(false);
        const timerRef2 = setTimeout(() => {
          setScreensaverVisible(true);
          loop();
        }, SCREENSAVER_INACTIVE_TIME_MS);
        clearTimeout(timerRef);
        secondTimer.current = timerRef2;
      }, SCREENSAVER_ACTIVE_TIME_MS);
      clearTimeout(secondTimer.current);
      secondTimer.current = timerRef;
    }
    loop();
    return () => clearTimeout(secondTimer.current);
  };

  useEffect(() => {
    const refreshTimerRef = setTimeout(() => {
      window.location.reload();
    }, 3600000);

    return () => clearTimeout(refreshTimerRef);
  }, []);

  const setScreensaverTimes = useCallback(() => {
    if (!boardData.screensaver_active_time) return;
    // requires minimum time to be 15 seconds for each time
    if (boardData.screensaver_active_time >= 15000) {
      setScreensaverActiveTime(boardData.screensaver_active_time);
    }
    if (boardData.screensaver_delay >= 15000) {
      setScreensaverDelay(boardData.screensaver_delay);
    }
    if (boardData.screensaver_inactive_time >= 15000) {
      setScreensaverInactiveTime(boardData.screensaver_inactive_time);
    }
  }, [boardData.assets_folder]);

  useEffect(() => {
    activeScreensaver();
  }, [
    SCREENSAVER_ACTIVE_TIME_MS,
    SCREENSAVER_DELAY_MS,
    SCREENSAVER_INACTIVE_TIME_MS,
  ]);

  const screensaverClicked = useCallback(() => {
    setScreensaverActive(false);
    startTimeout();
  }, []);

  const startTimeout = () => {
    clearTimeout(screensaverTimeout.current);
    clearTimeout(secondTimer.current);
    const timeout = setTimeout(() => activeScreensaver(), SCREENSAVER_DELAY_MS);
    screensaverTimeout.current = timeout;
    return () => clearTimeout(screensaverTimeout.current);
  };

  const appTouched = useCallback((ev) => {
    if (ev.target.id !== "screensaver") {
      startTimeout();
    }
  });

  const memoizedSitemaps = useCallback(() => {
    if (!boardData.assets_folder) {
      return;
    }
    cloudinaryService
      .getSitemaps({
        assetsFolder: boardData.assets_folder,
      })
      .then((res) => {
        const sitemaps = res.data.resources;

        if (!sitemaps.length) {
          throw new Error("NO SITEMAPS");
        }

        let foundDefault = false;
        const siteMapsAndUrls = {};

        sitemaps.forEach((sitemap) => {
          const splitPath = sitemap.public_id.split("/");
          const nameFromSitemap = splitPath.pop().split("_")[1];
          const name = nameFromSitemap.split("-").join(" ").toLowerCase();
          if (name === "default") foundDefault = true;
          siteMapsAndUrls[name] = sitemap.secure_url;
        });

        if (!foundDefault) {
          throw new Error("NO DEFAULT SITEMAP");
        }

        setAllSitemaps(siteMapsAndUrls);
      })
      .catch((err) => {
        console.log("error fetching sitemaps: ", err);
      });
  }, [boardData.assets_folder]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateState(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    // // Pings device on render
    const { board_name } = boardData;
    if (board_name) {
      clientAddressService.postClientAddress(board_name).catch((err) => {
        const retry = err.response.headers["retry-after"];
        if (retry) {
          const retryTime = parseInt(retry);
          setRateLimitHit(true);
          setRateLimitRetry(retryTime);
        } else {
          console.log("error getting board by name", err);
        }
      });
    }

    memoizedSitemaps();
    setScreensaverTimes();
  }, [boardData.assets_folder, params, rateLimitHit]);

  const updateSlideIndex = () => {
    setSlideIndex((prev) => {
      if (!slides.length) return 0;
      return (prev + 1) % slides.length;
    });
  };

  return (
    <ErrorBoundary>
      <div
        className="general-container"
        onClick={appTouched}
      >
        {rateLimitHit ? (
          <RateLimitHit numSeconds={rateLimitRetry} />
        ) : (
          <GeneralDirectoryContext.Provider
            value={{
              boardData,
              slides,
              defaultLogo,
              suites,
              slideIndex,
              updateSlideIndex,
            }}
          >
            <div
              className="general-side-column-container general-side-column"
              style={{ backgroundColor: boardData.accent_color || "#002352" }}
            >
              <h1 className="general-banner-header">
                {dateState.toLocaleString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </h1>
              <div className="general-video-container">
                <VideoAd
                  embedId={
                    boardData.playlist_key ||
                    "PLKinDjW_SsjLTgiaVG6Yf4FY_65N0WSvJ"
                  }
                />
              </div>
              {defaultLogo ? (
                <img
                  src={defaultLogo}
                  alt={"Logo for general directory"}
                  className="general-logo"
                />
              ) : null}
              <div className="general-qr-code-holder">
                <QRCode
                  size={150}
                  value={boardData.qr_code || "propervillainsllc.com"}
                />
              </div>
              <PoweredBy />
            </div>
            <div className="general-main-container">
              <div className="general-main-top">
                <img
                  src={allSitemaps[selectedSitemap] || allSitemaps["default"]}
                  alt={"Sitemaps failed"}
                  className="general-sitemap"
                />

                <div
                  // className="general-description"
                  className={
                    suites[selectedSitemap] && suites[selectedSitemap].contact
                      ? "general-description"
                      : "general-description general-description-no-text"
                  }
                  style={{
                    border: `solid 5px ${boardData.accent_color || "#002352"}`,
                  }}
                >
                  {suites[selectedSitemap] ? (
                    <>
                      {suites[selectedSitemap].contact ? (
                        <h3>{suites[selectedSitemap].contact}</h3>
                      ) : null}
                    </>
                  ) : null}
                  <p>
                    {suites[selectedSitemap]
                      ? suites[selectedSitemap].description
                      : null}
                  </p>
                </div>
              </div>
              <div className="general-main-bottom">
                <Navigation
                  sitemaps={allSitemaps}
                  accentColor={boardData.accent_color || "#002352"}
                  selectedSitemap={selectedSitemap}
                  setSelectedSitemap={setSelectedSitemap}
                />
              </div>
            </div>

            <div>
              {screensaverActive && screensaverVisible && (
                <GeneralScreenSaver
                  clicked={screensaverClicked}
                  accentColor={boardData.accent_color || "#002352"}
                  board={boardData}
                  website={boardData.qr_code}
                />
              )}
            </div>
          </GeneralDirectoryContext.Provider>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default GeneralDirectory;
