import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class CloudinaryService {
  getBackgroundImages() {
    return axios.get(API + "background-images", { headers: authHeader() });
  }

  createBaseFolder(path) {
    return axios.post(API + "cloudinary/create-base-folder", path, {
      headers: authHeader(),
    });
  }

  getLogos(boardName) {
    return axios.post(API + "cloudinary/get-logos", boardName, {
      headers: authHeader(),
    });
  }

  getSlides(data) {
    return axios.post(API + "cloudinary/get-slides", data, {
      headers: authHeader(),
    });
  }

  uploadLogo(data) {
    return axios.post(API + "cloudinary/upload-logo", data, {
      headers: authHeader(),
    });
  }

  uploadSlides(data) {
    return axios.post(API + "cloudinary/upload-slides", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteSlides(data) {
    return axios.post(API + "cloudinary/delete-slides", data, {
      headers: authHeader(),
    });
  }

  getSitemaps(data) {
    return axios.post(API + "cloudinary/get-sitemaps", data, {
      headers: authHeader(),
    });
  }

  getDefaultSitemap(data) {
    return axios.post(API + "cloudinary/get-default-sitemap", data, {
      headers: authHeader(),
    });
  }

  deleteAllSiteMaps(data) {
    return axios.post(API + "cloudinary/delete-all-sitemaps", data, {
      headers: authHeader(),
    });
  }

  uploadSiteMaps(data) {
    return axios.post(API + "cloudinary/upload-sitemaps", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new CloudinaryService();
