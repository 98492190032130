import axios from "axios";

const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";
const weatherAPI = "https://api.weather.gov/";

class WeatherService {
  getLocationInfo(lat, long) {
    return axios.get(weatherAPI + `points/${lat},${long}`);
  }

  getForecast(url) {
    return axios.get(url);
  }

  getAddress(address) {
    return axios.post(API + "coordinates", address);
  }
}

export default new WeatherService();
