import React, { useEffect, useState, useCallback } from "react";
import "./digitalSignage.css";
import { useParams } from "react-router-dom";
import boardService from "../../services/board.service";
import cloudinaryService from "../../services/cloudinary.service";
import VideoAd from "../../components/digitalSignage/VideoAd";
import Ticker from "../../components/digitalSignage/Ticker";
import Carousel from "../../components/digitalSignage/Carousel";
import PoweredBy from "../../components/digitalSignage/PoweredBy";
import QRCode from "../../components/directory/QRCode";
import HourlyWeather from "../../components/digitalSignage/HourlyWeather";
import Announcements from "../../components/digitalSignage/Announcements";
import clientAddressService from "../../services/clientAddress.service";
import RateLimitHit from "../../components/rateLimitHit/RateLimitHit";

function DigitalSignage() {
  const params = useParams();
  const [dateState, setDateState] = useState(new Date());
  const [board, setBoard] = useState({});
  const [slides, setSlides] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [rateLimitHit, setRateLimitHit] = useState(false);
  const [rateLimitRetry, setRateLimitRetry] = useState(0);

  const getSlides = useCallback(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    if (params) {
      const folder = params.dir.split(" ").join("-").toLowerCase();
      boardService
        .getBoardByName({ board_name: params.dir })
        .then((res) => {
          console.log(res.data);
          setBoard(res.data);
          setSelectedWidgets(res.data.widgets);
          cloudinaryService
            .getSlides({ assetsFolder: folder })
            .then((slides) => {
              setSlides(slides.data.resources);
            });
        })
        .catch((err) => {
          const retry = err.response.headers["retry-after"];
          if (retry) {
            const retryTime = parseInt(retry);
            setRateLimitHit(true);
            setRateLimitRetry(retryTime);
          } else {
            console.log("error getting board by name", err);
          }
        });
    }
  }, [params, rateLimitHit]);

  const address = {
    street: board.street,
    city: board.city,
    state: board.state,
    zipCode: board.zip_code,
  };

  useEffect(() => {
    getSlides();
    const interval = setInterval(() => {
      console.log("interval for time");
      setDateState(new Date());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const refreshTimerRef = setTimeout(() => {
      window.location.reload();
    }, 3600000);

    return () => clearTimeout(refreshTimerRef);
  }, []);

  useEffect(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    const { board_name } = board;

    if (board_name) {
      clientAddressService.postClientAddress(board_name).catch((err) => {
        const retry = err.response.headers["retry-after"];
        if (retry) {
          const retryTime = parseInt(retry);
          setRateLimitHit(true);
          setRateLimitRetry(retryTime);
        } else {
          console.log("error getting board by name", err);
        }
      });
    }
  }, [board, rateLimitHit]);

  return (
    <div className="signage-container">
      {rateLimitHit ? (
        <RateLimitHit numSeconds={rateLimitRetry} />
      ) : (
        <>
          <div className="side-column-container">
            <h1 className="banner-header">
              {dateState.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </h1>
            <VideoAd embedId={board.playlist_key} />
            {selectedWidgets ? (
              <div className="widget-container">
                {selectedWidgets.includes("Announcements") &&
                board.board_announcements ? (
                  <Announcements announcement={board.board_announcements} />
                ) : null}
                {selectedWidgets.includes("Hourly Weather") ? (
                  <HourlyWeather address={address} />
                ) : null}
              </div>
            ) : (
              <div className="widget-container"></div>
            )}
            <div className="qr-code-holder">
              <QRCode
                size={100}
                value={board.qr_code}
              />
            </div>
            <Ticker />
            <PoweredBy />
          </div>
          <Carousel slides={slides} />
        </>
      )}
    </div>
  );
}

export default DigitalSignage;
