import { Modal } from "react-bootstrap";
import { PulseLoader } from "react-spinners";

export default function ConfirmPanic({
  isOpen,
  handleClose,
  handleConfirm,
  isPanicModeActive,
  isPending,
}) {
  const enableText =
    "Are you sure you want to enable panic mode? This will revert all boards to a previous version and should only be done in an emergency.";

  const disableText =
    "Are you sure you want to disable panic mode? This will return all boards to the most recent version.";

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
    >
      {isPending ? (
        <div className="my-5 mx-5 d-flex flex-column justify-content-center align-items-center">
          <PulseLoader
            size={20}
            color="#09527f"
          />
          <p className="mt-2 mb-0">Switching Panic State...</p>
        </div>
      ) : (
        <div className="my-4 mx-4 d-flex flex-column text-center">
          <strong className="my-2">
            {isPanicModeActive ? disableText : enableText}
          </strong>
          <div className="mt-4 mb-2 mx-3 d-flex justify-content-between align-items-center">
            <button
              onClick={handleClose}
              className="btn btn-primary"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className={`btn btn-${isPanicModeActive ? "success" : "danger"}`}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}
