/* eslint-disable jsx-a11y/alt-text */
import { useMemo } from "react";
import boardService from "../../services/board.service";
import "animate.css";

function ImageSlider({ images, boardId }) {
  return (
    <div className="picture-orbit">
      <div className="images">
        <img
          className="orbit-one animate__animated animate__flip "
          style={{ marginLeft: "35rem" }}
          src={images[0].media_url}
        />
        <img
          className="orbit-two animate__animated animate__flip"
          style={{ marginLeft: "38rem" }}
          src={images[1].media_url}
        />
        <img
          className="orbit-three animate__animated animate__flip"
          style={{ marginLeft: "41rem" }}
          src={images[2].media_url}
        />
        <img
          className="orbit-two animate__animated animate__flip"
          style={{ marginLeft: "38rem" }}
          src={images[3].media_url}
        />
        <img
          className="orbit-one animate__animated animate__flip"
          style={{ marginLeft: "35rem" }}
          src={images[4].media_url}
        />
      </div>
    </div>
  );
}

export default ImageSlider;
